/* eslint-disable @typescript-eslint/no-unsafe-argument */
import type {
  FilterModel,
  IFilterConfigDTO,
  IFilterSelectionValue,
  IGalleryControllerConfig,
  IGetInitialData,
  ImageModeId,
  ImagePositions,
  ImageRatioId,
  IProduct,
  IPropsInjectedByViewerScript,
  ISorting,
  ISortingOption,
  ITextsMap,
  PaginationTypeName,
  ProductsManifest,
  VeloInputs,
} from '../types/galleryTypes';
/* eslint-disable prefer-const */
/* eslint-disable import/no-cycle */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  GridType,
  HoverType,
  ImagePlacements,
  LoadMoreType,
  MobileFiltersPanelState,
  PaginationType,
} from '../types/galleryTypes';
import {
  AddToCartActionStatus,
  BiEventParam,
  CategoryListStrategy,
  DEFAULT_COLLECTION_ID,
  DEFAULT_COLS,
  DEFAULT_MOBILE_PRODUCTS_COUNT,
  DEFAULT_ROWS,
  Experiments,
  FedopsInteraction,
  SlotIds,
  sortOptions,
  SortOptionsIds,
  STORES_CATEGORY_SEO,
  STORES_GALLERY_SEO,
  STORES_NAMESPACE,
  translationPath,
} from '../constants';
import {
  AddToCartActionOption,
  APP_DEFINITION_ID,
  PageMap,
  StoresWidgetID,
} from '@wix/wixstores-client-core/dist/es/src/constants';
import {FilterConfigsService} from '../services/FilterConfigsService';
import {FiltersService} from './FiltersService';
import {getReleaseFromBaseUrl} from '@wix/native-components-infra/dist/es/src/sentryUtils/getReleaseFromBaseUrl';
import {MultilingualService} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/MultilingualService/MultilingualService';
import {ProductsService} from '../services/ProductsService';
import {DefaultQueryParamKeys, GalleryQueryParamsService} from '../services/GalleryQueryParamsService';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {SortService} from '../services/SortService';
import {getTranslations} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/utils';
import {getInitialProductsCountToFetch} from './utils';
import {AddToCartService} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/AddToCartService/AddToCartService';
import {actualPrice, isPreOrder} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import type {ISentryErrorBoundaryPropsInjectedByViewerScript} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import {CustomUrlApi} from '@wix/wixstores-client-storefront-sdk/dist/es/src/utils/CustomUrl/CustomUrlApi';
import {BaseGalleryStore} from './BaseGalleryStore';
import _ from 'lodash';
import {ProductPriceRange} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/ProductPriceRange/ProductPriceRange';
import {unitsTranslations} from '../common/components/ProductItem/ProductPrice/unitsTranslations';
import {ProductPriceBreakdown} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/ProductPriceBreakdown/ProductPriceBreakdown';
import {
  categoryPageBreadcrumbClicked,
  categoryPageCategoryTreeClicked,
  categoryPageHeroSectionReadLessClicked,
  categoryPageHeroSectionReadMoreClicked,
  clickLoadMoreInGallerySf,
  clickOnProductBoxSf,
  clickToChangeGalleryFiltersSf,
  exposureEventForTests,
  galleryClickApplyFilter,
  galleryClickClearAllFilters,
  galleryClickFilter,
  galleryClickSortBy,
  sortGallerySf,
  viewGallerySf,
} from '@wix/bi-logger-ec-sf/v2';
import {ICategory} from '../types/category';
import {GetCategoryInitialDataQuery} from '../graphql/queries-schema';
import type {ILink} from '@wix/wixstores-client-core';
import {galleryColumnsDefaultValue, galleryRowsDefaultValue} from '../styleParams/baseStylesParams';
import gridGalleryStylesParams from '../components/GridGallery/stylesParams';
import categoryStylesParams from '../components/CategoryPage/stylesParams';
import {BreadcrumbsItem} from 'wix-ui-tpa';
import {CategoriesService} from '../services/CategoriesService';
import {createSlotVeloAPIFactory} from '@wix/widget-plugins-ooi/velo';

export class GalleryStore extends BaseGalleryStore {
  private addedToCartStatus: {[p: string]: AddToCartActionStatus} = {};
  private currentPage: number = 1;
  private scrollToProduct: string;
  private filterConfigsService: FilterConfigsService;
  private filtersService: FiltersService;
  private isFedopsReport: boolean = true;
  private multilingualService: MultilingualService;
  private categoriesService: CategoriesService;
  private productPageSectionUrl: string;
  private readonly productsPerPage: number;
  private readonly queryParamsService: GalleryQueryParamsService;
  private readonly fedopsLogger;
  private readonly sortService: SortService;
  private translations;
  private readonly customUrlApi: CustomUrlApi;
  private isUrlWithOverrides: boolean = false;
  private mainCollectionId: string = null;
  private currentCategory: ICategory;
  private homepageUrl: string;
  private breadcrumbsItems: any[];
  private isCategoryVisible: boolean = true;
  private galleryRows: number;
  private products: IProduct[];

  constructor(
    config: IGalleryControllerConfig,
    updateComponent: (props: Partial<IPropsInjectedByViewerScript>) => void,
    siteStore: SiteStore,
    private readonly compId: string,
    type: string,
    private readonly reportError: (e) => any,
    private readonly slotAPIFactory: ReturnType<typeof createSlotVeloAPIFactory>,
    private veloInputs?: VeloInputs
  ) {
    super(config, siteStore, updateComponent, type);
    this.queryParamsService = new GalleryQueryParamsService(
      this.siteStore,
      this.siteStore.isMobile(),
      this.isCategoryPage
    );

    const fedopsLoggerFactory = this.siteStore.platformServices.fedOpsLoggerFactory;
    this.fedopsLogger = fedopsLoggerFactory.getLoggerForWidget({
      appId: APP_DEFINITION_ID,
      widgetId: this.type,
    });

    this.sortService = new SortService();

    this.addToCartService = new AddToCartService(this.siteStore, this.publicData);
    this.setGalleryRows();
    const {gallery_gridType, gallery_productsCount} = this.styles;

    const isAutoGrid = gallery_gridType === GridType.AUTO;
    const initialProductsCount = getInitialProductsCountToFetch({
      isMobile: this.siteStore.isMobile(),
      isEditor: this.siteStore.isEditorMode(),
      isAutoGrid,
      rows: this.galleryRows,
      cols: this.galleryColumns,
      autoGridProductsCount: gallery_productsCount,
      isGalleryRowsAndColsWereModified: this.isGalleryRowsAndColsWereModified,
      isFixedProductsCountModified: this.isFixedProductsCountModified,
    });

    this.productsService = new ProductsService(
      this.siteStore,
      initialProductsCount,
      'Grid Gallery',
      this.shouldShowProductOptions,
      this.shouldRenderPriceRange,
      this.fedopsLogger,
      this.type === StoresWidgetID.GALLERY_PAGE
    );
    this.productsPerPage = this.productsService.getProductPerPage();
    this.customUrlApi = new CustomUrlApi(this.siteStore.location.buildCustomizedUrl);
  }

  private get isCategoryPage() {
    return this.type === StoresWidgetID.CATEGORY_PAGE;
  }

  private renderCategorySEOTags() {
    const {prevUrl, nextUrl} = this.nextPrevLinksMapped();
    const categoryUrl = this.queryParamsService.getUrl();
    const itemType = STORES_CATEGORY_SEO;
    const itemData = {
      category: {
        categoryUrl,
        categoryName: this.currentCategory.name,
        categoryDesc: this.currentCategory.description,
        numberOfItems: this.productsService.totalCount,
        image: {
          imageUrl: this.currentCategory.media?.fullUrl,
          imageWidth: this.currentCategory.media?.width,
          imageHeight: this.currentCategory.media?.height,
          hasImage: !!this.currentCategory.media,
          imageAlt: this.currentCategory.name,
        },
        pagination: {
          prevUrl,
          nextUrl,
          totalPages: this.totalPages,
          currentPage: this.currentPage,
        },
      },
    };
    const seoData = this.currentCategory.seoData && JSON.parse(this.currentCategory.seoData);

    this.siteStore.seo.renderSEOTags({
      itemType,
      itemData,
      seoData,
    });
  }

  private renderGallerySEOTags() {
    const {prevUrl, nextUrl} = this.nextPrevLinksMapped();
    const url = this.queryParamsService.getUrlWithCustomPageParamForSeo(this.currentPage);
    const itemType = STORES_GALLERY_SEO;
    const itemData = {
      shop: {
        url,
      },
      pagination: {
        prevUrl,
        nextUrl,
        totalPages: this.totalPages,
        currentPage: this.currentPage,
      },
      items: {
        numberOfItems: this.productsService.totalCount,
      },
    };

    this.siteStore.seo.renderSEOTags({
      itemType,
      itemData,
    });
  }

  private isStyleParamModified(styleParamName: string) {
    return !!this.config.style.styleParams?.numbers?.[styleParamName];
  }

  private get isGalleryRowsAndColsWereModified() {
    return this.isStyleParamModified('galleryRows') && this.isStyleParamModified('galleryColumns');
  }

  private get isFixedProductsCountModified(): boolean {
    return this.isStyleParamModified('gallery_fixedGridProductsCount');
  }

  private get shouldRenderPriceRange(): boolean {
    return new ProductPriceRange(this.siteStore).shouldShowPriceRange();
  }

  private get sentryErrorBoundaryProps(): ISentryErrorBoundaryPropsInjectedByViewerScript {
    return {
      ravenUserContextOverrides: {id: this.siteStore.storeId, uuid: this.siteStore.uuid as string},
      sentryRelease: getReleaseFromBaseUrl(this.siteStore.baseUrls.galleryBaseUrl, {
        artifactName: true,
      }),
    };
  }

  private getStyleParamByDevice<T>(mobileStyleParam: T, defaultStyleParam: T, experimentEnabled = true): T {
    if (experimentEnabled && this.siteStore.isMobile() && mobileStyleParam !== undefined) {
      return mobileStyleParam;
    }
    return defaultStyleParam;
  }

  private get shouldShowImageCarousel(): boolean {
    const hoverType = this.getStyleParamByDevice(
      this.styles['mobile:gallery_imageEffect'],
      this.styles.gallery_hoverType
    );

    return (
      this.siteStore.experiments.enabled(Experiments.GalleryProductItemCarouselHover) &&
      hoverType.value === HoverType.Carousel
    );
  }

  private get shouldShowAddToCartButton(): boolean {
    return this.getStyleParamByDevice(
      this.styles['mobile:gallery_showAddToCartButton'],
      this.styles.gallery_showAddToCartButton
    );
  }

  protected get shouldShowProductOptions(): boolean {
    const styleParam = this.siteStore.isMobile()
      ? this.stylesParams['mobile:gallery_showProductOptions']
      : this.stylesParams.gallery_showProductOptionsButton;

    return this.isTrueInAnyBreakpoint(styleParam);
  }

  private get shouldShowQuantity(): boolean {
    return this.getStyleParamByDevice(
      this.styles['mobile:gallery_showQuantity'],
      this.styles.gallery_showAddToCartQuantity
    );
  }

  public setVeloInputs(veloInputs: VeloInputs) {
    this.veloInputs = {
      ...this.veloInputs,
      ...veloInputs,
    };
  }

  private getTranslation() {
    return this.translations
      ? Promise.resolve(this.translations)
      : getTranslations(translationPath(this.siteStore.baseUrls.galleryBaseUrl, this.siteStore.locale));
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public async setInitialState(): Promise<any> {
    this.isCategoryPage ? await this.setCategoryInitialState() : await this.setGalleryInitialState();
  }

  private get galleryColumns() {
    /* istanbul ignore next: I think it will never reach DEFAULT_COLS, but i'm afraid to remove */
    return (
      this.styles.galleryColumns ?? galleryColumnsDefaultValue({dimensions: this.config.dimensions}) ?? DEFAULT_COLS
    );
  }

  private setGalleryRows(shouldUpdateFixedProductsCount: boolean = false): void {
    if (this.isFixedProductsCountModified || shouldUpdateFixedProductsCount) {
      const styleParamProductsCount = this.styles.gallery_fixedGridProductsCount;
      this.galleryRows = styleParamProductsCount / this.galleryColumns;
      return;
    }

    this.galleryRows =
      this.styles.galleryRows ??
      galleryRowsDefaultValue({getStyleParamValue: () => this.galleryColumns}) ??
      /* istanbul ignore next: I think it will never reach DEFAULT_ROWS, but i'm afraid to remove */
      DEFAULT_ROWS;
  }

  private get maxProductsPerPage() {
    const shouldUseMobileDefaults =
      this.siteStore.isMobile() &&
      !this.isGalleryRowsAndColsWereModified &&
      !this.isFixedProductsCountModified &&
      !this.config.usesCssPerBreakpoint;

    switch (true) {
      case this.shouldUseAutoGridProductsCount:
        return this.styles.gallery_productsCount;
      case shouldUseMobileDefaults:
        return DEFAULT_MOBILE_PRODUCTS_COUNT;
      default:
        return this.galleryColumns * this.galleryRows;
    }
  }

  private get totalPages() {
    return Math.ceil(this.productsService.totalCount / this.maxProductsPerPage);
  }

  private async setGalleryInitialState() {
    let data, translations, url, sorting: ISortingOption, limit;

    if (this.isAutoGrid()) {
      limit = this.styles.gallery_productsCount;
    }

    if (this.siteStore.location.query.sort) {
      sorting = this.getSortFromQueryParam();
    }

    if (this.siteStore.location.query.page) {
      limit = this.getProductsLimitByPageFromQueryParam(this.productsService.getProductPerPage());
    }

    this.currentPage = this.queryParamsService.getPageQueryParam();

    if (this.shouldScrollToProductPositionWhenReturningFromProductPageEnabled()) {
      this.scrollToProduct = this.queryParamsService.getQueryParam('scrollToProduct');
    }

    [translations, data, {url}] = await Promise.all([
      this.getTranslation(),
      this.productsService.getInitialData(this.getInitialDataOptions(sorting, limit)),
      this.siteStore.getSectionUrl(PageMap.PRODUCT),
    ]).catch(this.reportError);

    this.isUrlWithOverrides = await this.customUrlApi.init();
    this.isCategoryVisible = data.catalog.category.visible;

    sorting && this.sortService.setSelectedSort(sorting.id);

    this.products = data.catalog.category.productsWithMetaData.list;
    this.translations = translations;

    this.productPriceBreakdown = new ProductPriceBreakdown(this.siteStore, this.translations, {
      excludedPattern: 'gallery.price.tax.excludedParam.label',
      includedKey: 'gallery.price.tax.included.label',
      includedPattern: 'gallery.price.tax.includedParam.label',
      excludedKey: 'gallery.price.tax.excluded.label',
    });

    this.productPageSectionUrl = url;
    this.mainCollectionId = this.productsService.getMainCollectionId();

    this.multilingualService = new MultilingualService(
      this.siteStore,
      this.publicData.COMPONENT,
      data.appSettings.widgetSettings
    );

    this.filterConfigsService = new FilterConfigsService(
      data.appSettings.widgetSettings.FILTERS,
      this.publicData.COMPONENT?.FILTERS?.data,
      this.styles,
      this.multilingualService,
      translations
    );

    if (sorting) {
      this.productsService.updateSort(sorting);
    }

    this.filtersService = new FiltersService(
      this.siteStore,
      this.mainCollectionId,
      this.filterConfigsService,
      this.productsService.allProductsCategoryId
    );
    let filterModels: FilterModel[] | [] = [];
    if (this.shouldShowFilters()) {
      filterModels = await this.fetchFilters();

      filterModels = this.getFiltersFromQueryParams(filterModels);
      this.productsService.updateFilters(this.filtersService.getFilterDTO());

      this.products = await this.getFilterProducts({limit});
    }

    if (this.loadMoreType() === LoadMoreType.PAGINATION && this.queryParamsService.getQueryParam('page')) {
      this.products = await this.loadProductsByPage(this.currentPage);
    }

    let isFirstPage = true;

    if (this.currentPage > 1) {
      isFirstPage = false;
    }

    if (this.siteStore.experiments.enabled(Experiments.AddRatingSummarySlotToGallery)) {
      this.setSlotProps(this.products);
    }

    if (this.isGallerySeoTagsEnabled()) {
      this.renderGallerySEOTags();
    }

    this.updateComponent({
      ...this.getPropsToInject(this.products, filterModels),
      isFirstPage,
    });

    if (!this.siteStore.isSSR()) {
      this.productsService.storeNavigation(this.siteStore.siteApis.currentPage.id);
    }
  }

  private setSlotProps(products) {
    try {
      const slot = this.slotAPIFactory.getSlotAPI(SlotIds.ProductGalleryDetailsSlot1);
      slot.namespace = STORES_NAMESPACE;
      slot.resourceList = products.map((product) => ({id: product.id}));
    } catch (e) {
      /* istanbul ignore next: nothing to test  */
      this.reportError(e);
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  private async setCategoryInitialState() {
    let initialData: GetCategoryInitialDataQuery,
      translations,
      productPageUrl,
      sorting: ISortingOption,
      limit,
      isUrlWithOverrides,
      homePage: ILink;

    if (this.isAutoGrid()) {
      limit = this.styles.gallery_productsCount;
    }

    if (this.siteStore.location.query.sort) {
      sorting = this.getSortFromQueryParam();
    }

    if (this.siteStore.location.query.page) {
      limit = this.getProductsLimitByPageFromQueryParam(this.productsService.getProductPerPage());
    }

    if (this.shouldScrollToProductPositionWhenReturningFromProductPageEnabled()) {
      this.scrollToProduct = this.queryParamsService.getQueryParam('scrollToProduct');
    }

    this.currentPage = this.queryParamsService.getPageQueryParam();

    [translations, {data: initialData}, {url: productPageUrl}, isUrlWithOverrides, homePage] = await Promise.all([
      this.getTranslation(),
      this.productsService.getCategoryInitialData(this.config.externalId),
      this.siteStore.getSectionUrl(PageMap.PRODUCT),
      this.customUrlApi.init(),
      this.siteStore.getHomepageLink(),
    ]).catch(this.reportError);

    this.translations = translations;
    this.multilingualService = new MultilingualService(
      this.siteStore,
      this.publicData.COMPONENT,
      initialData.appSettings.widgetSettings
    );

    this.isUrlWithOverrides = isUrlWithOverrides;
    this.productPageSectionUrl = productPageUrl;

    this.productPriceBreakdown = new ProductPriceBreakdown(this.siteStore, this.translations, {
      excludedPattern: 'gallery.price.tax.excludedParam.label',
      includedKey: 'gallery.price.tax.included.label',
      includedPattern: 'gallery.price.tax.includedParam.label',
      excludedKey: 'gallery.price.tax.excluded.label',
    });
    this.homepageUrl = homePage.url;

    this.filterConfigsService = new FilterConfigsService(
      initialData.appSettings.widgetSettings.FILTERS,
      this.publicData.COMPONENT?.FILTERS?.data,
      this.styles,
      this.multilingualService,
      translations,
      this.isCategoryPage
    );
    this.filtersService = new FiltersService(
      this.siteStore,
      this.mainCollectionId,
      this.filterConfigsService,
      this.productsService.allProductsCategoryId,
      true
    );
    const routerData = this.siteStore.windowApis.getRouterPublicData<{slug: string}>();

    this.categoriesService = new CategoriesService(
      initialData.catalog.categories.list,
      this.siteStore,
      this.productsService.allProductsCategoryId
    );
    this.categoriesService.updateVisibleCategories({
      categoryListConfig: initialData.appSettings.widgetSettings.CATEGORY_LIST_CONFIG as IFilterConfigDTO,
      shouldUseCategoryListConfig: this.styles.gallery_categoryListStrategy === CategoryListStrategy.MANUALLY,
    });
    const categoryFromSlug = this.categoriesService.getCategoryBySlug(routerData?.slug);

    if (!categoryFromSlug) {
      this.updateComponent({
        ...this.getEmptyStatePropsToInject(),
      });
      return null;
    }

    this.setCurrentCategory(categoryFromSlug);

    if (sorting) {
      this.sortService.setSelectedSort(sorting.id);
      this.productsService.updateSort(sorting);
    } else {
      this.sortService.setSelectedSortBySettingsShouldDisplayKey(this.styles.gallery_sortingDefaultOption.value);
      this.productsService.updateSort(this.sortService.getSelectedSort());
    }

    let filterModels: FilterModel[] = [];
    if (this.shouldShowFilters()) {
      const serverFilterModels = await this.filtersService.fetchFilters();
      filterModels = this.getFiltersFromQueryParams(serverFilterModels);
      this.productsService.updateFilters(this.filtersService.getFilterDTO());
    }

    if (this.loadMoreType() === LoadMoreType.PAGINATION && this.currentPage) {
      this.products = await this.loadProductsByPage(this.currentPage);
    } else {
      this.products = await this.getFilterProducts({limit});
    }

    if (this.siteStore.experiments.enabled(Experiments.AddRatingSummarySlotToGallery)) {
      this.setSlotProps(this.products);
    }

    this.renderCategorySEOTags();

    this.updateComponent({
      ...this.getPropsToInject(this.products, filterModels),
      isFirstPage: this.currentPage <= 1,
    });

    if (!this.siteStore.isSSR()) {
      this.productsService.storeNavigation(this.siteStore.siteApis.currentPage.id);
    }
  }

  private getInitialDataOptions(
    sorting: ISortingOption,
    limit: number
  ): Omit<IGetInitialData, 'withOptions' | 'withPriceRange'> {
    let initialDataOptions = {
      externalId: this.config.externalId,
      compId: this.compId,
      sort: this.sortService.getSortDTO(sorting),
      filters: null,
      limit,
      offset: 0,
      mainCollectionId: this.mainCollectionId,
    };

    if (this.veloInputs?.collectionId) {
      initialDataOptions = {...initialDataOptions, mainCollectionId: this.veloInputs.collectionId};
    } else if (this.veloInputs?.productIds) {
      initialDataOptions = {
        ...initialDataOptions,
        mainCollectionId: this.siteStore.experiments.enabled(Experiments.UseServerAllProductsCategoryId)
          ? this.productsService.allProductsCategoryId
          : DEFAULT_COLLECTION_ID,
        filters: {
          term: {
            field: 'id',
            op: 'IN',
            values: this.veloInputs.productIds,
          },
        },
      };
    }
    return initialDataOptions;
  }

  public onAppLoaded(): void {
    /* istanbul ignore else */
    if (this.isFedopsReport) {
      this.isFedopsReport = false;

      /* istanbul ignore next: debug */
      if (
        this.siteStore.experiments.enabled('specs.stores.ReportBiForEmptyGallery') &&
        !this.productsService.products.length
      ) {
        this.siteStore.webBiLogger.report(exposureEventForTests({testName: `empty-gallery-${this.type}`}));
      }

      this.siteStore.webBiLogger.report(viewGallerySf(this.getViewGallerySfParams()));
    }
  }

  private getViewCategoriesSfParams() {
    return {
      isCategoryPage: this.isCategoryPage,
      hasHeroImage: this.currentCategory?.media?.url && this.styles.gallery_showCategoryHeaderImage,
      hasHeroDescription: this.currentCategory?.description && this.styles.gallery_showCategoryHeaderDescription,
      hasProductCounter: this.styles.gallery_showCategoriesProductsCounter,
      hasBreadcrumbs: this.styles.gallery_showCategoriesBreadcrumbs,
      hasCategoryTree: this.styles.gallery_showCategories,
    };
  }

  private getViewGallerySfParams() {
    const {
      galleryShowFilters,
      gallery_showAppliedFilters,
      gallery_showPrice,
      showQuickView,
      galleryShowSort,
      gallery_loadMoreProductsType,
    } = this.styles;

    const hoverType = this.styles.gallery_hoverType.value;

    const loadType = {
      [LoadMoreType.BUTTON]: BiEventParam.LoadMore,
      [LoadMoreType.PAGINATION]: BiEventParam.Pagination,
      [LoadMoreType.INFINITE]: BiEventParam.InfiniteScroll,
    }[gallery_loadMoreProductsType];
    let res = {
      isMobileFriendly: this.siteStore.isMobileFriendly,
      addToCart: this.shouldShowAddToCartButton,
      filterType:
        (galleryShowFilters &&
          this.filtersService
            ?.getFilterModels()
            .map((m) => m.filterType)
            .join(',')) ||
        '',
      hasAppliedFilters: gallery_showAppliedFilters,
      hasOptions: this.shouldShowProductOptions,
      hasPrice: gallery_showPrice,
      hasQuantity: this.shouldShowQuantity,
      hasQuickView: showQuickView,
      hasSorting: galleryShowSort,
      hoverType,
      loadType,
      numOfColumns: this.isAutoGrid() ? undefined : this.galleryColumns,
      navigationClick: this.getNavigationClick(),
      productsLogic:
        this.mainCollectionId !== this.productsService.allProductsCategoryId ? 'collection' : 'All products',
      priceBreakdown: this.productPriceBreakdown.priceBreakdownBIParam,
      type: this.type,
    };
    if (this.isCategoryPage) {
      res = {...res, ...this.getViewCategoriesSfParams()};
    }
    return res;
  }

  private getNavigationClick(): string {
    const {gallery_showAddToCartButton} = this.styles;
    if (!gallery_showAddToCartButton) {
      return '';
    } else if (this.getAddToCartAction() === AddToCartActionOption.MINI_CART) {
      return 'mini-cart';
    } else if (this.getAddToCartAction() === AddToCartActionOption.CART) {
      return 'cart';
    }
    return 'none';
  }

  private readonly shouldShowClearFilters = () => {
    if (this.siteStore.isMobile()) {
      return true;
    }
    const hasSelectedFilters = !!this.filtersService.getSelectedFilterTypes().length;

    if (this.isCategoryPage) {
      const appliedFiltersShown = this.styles.gallery_showAppliedFilters;
      return hasSelectedFilters && !appliedFiltersShown;
    }

    return hasSelectedFilters;
  };

  private readonly isGallerySeoTagsEnabled = () => {
    return this.siteStore.experiments.enabled(Experiments.GallerySeoTags);
  };

  protected getEmptyStatePropsToInject() {
    return {
      isCategoryVisible: this.isCategoryVisible,
      currentCategory: this.currentCategory,
      isEditorMode: this.siteStore.isEditorMode(),
      textsMap: this.getTextsMap(),
      onAppLoaded: this.onAppLoaded.bind(this),
      loading: false,
      isFirstPage: true,
      productsRequestInProgress: false,
      isHorizontalLayout: this.isHorizontalLayout(),
      isInteractive: this.siteStore.isInteractive(),
      isLiveSiteMode: this.siteStore.isSiteMode(),
      isLoaded: true,
      experiments: this.getExperimentsToInject(),
      shouldShowMobile: this.siteStore.isMobile(),
      isPreviewMode: this.siteStore.isPreviewMode(),
      isRTL: this.siteStore.isRTL(),
      cssBaseUrl: this.siteStore.baseUrls.galleryBaseUrl,
      fitToContentHeight: true, // thunderbolt prop that sets height: auto on the widget instead of fixed height
      imagePosition: this.imagePosition(),
    };
  }

  protected getPropsToInject(products: IProduct[], filterModels: FilterModel[] | []): IPropsInjectedByViewerScript {
    return {
      ...this.getComputedProps(products),
      ...this.sentryErrorBoundaryProps,
      ...super.getCommonPropsToInject(),
      isCategoryVisible: this.isCategoryVisible,
      isEditorMode: this.siteStore.isEditorMode(),
      fitToContentHeight: true, // thunderbolt prop that sets height: auto on the widget instead of fixed height
      allowFreeProducts: this.addToCartService.allowFreeProducts,
      addedToCartStatus: this.addedToCartStatus,
      categories: this.categories,
      clearFilters: this.clearFilters.bind(this),
      cssBaseUrl: this.siteStore.baseUrls.galleryBaseUrl,
      linkForAllPages: this.getLinksForAllPages(),
      nextPrevLinks: this.nextPrevLinks(),
      totalPages: this.totalPages,
      maxProductsPerPage: this.maxProductsPerPage,
      filterModels,
      clearScrollToProduct: () => this.queryParamsService.updateScrollToProduct(),
      filterProducts: this.filterProducts.bind(this),
      applyFilteredProductsOnMobile: this.applyFilteredProductsOnMobile.bind(this),
      handleAddToCart: this.handleAddToCart.bind(this),
      sendSortClickBiEvent: this.sendSortClickBiEvent.bind(this),
      handlePagination: this.handlePagination.bind(this),
      handleProductItemClick: this.handleProductItemClick.bind(this),
      handleCategoryClick: this.handleCategoryClick.bind(this),
      handleCategoryBreadcrumbsClick: this.handleCategoryBreadcrumbsClick.bind(this),
      handleCategoryClampClick: this.handleCategoryClampClick.bind(this),
      hasMoreProductsToLoad: this.productsService.hasMoreProductsToLoad(),
      hasSelectedFilters: this.hasSelectedFilters(),
      imagePosition: this.imagePosition(),
      isAutoGrid: this.isAutoGrid(),
      isCategoryPage: this.isCategoryPage,
      isFirstPage: true,
      isHorizontalLayout: this.isHorizontalLayout(),
      isInteractive: this.siteStore.isInteractive(),
      isLiveSiteMode: this.siteStore.isSiteMode(),
      isLoaded: true,
      shouldShowMobile: this.siteStore.isMobile(),
      isPreviewMode: this.siteStore.isPreviewMode(),
      isRTL: this.siteStore.isRTL(),
      isOptionsRevealEnabled: this.getIsOptionsRevealEnabled(),
      loadMoreProducts: this.loadMoreProducts.bind(this),
      loadMoreType: this.loadMoreType(),
      loading: false,
      mainCollectionId: this.mainCollectionId,
      numberOfSelectedFilterTypes: this.getNumberOfSelectedFilterTypes(),
      onAppLoaded: this.onAppLoaded.bind(this),
      openQuickView: this.openQuickView.bind(this),
      paginationMode: this.getPaginationMode(),
      selectedSort: this.sortService.getSelectedSort(),
      shouldAlternateImagePosition: this.shouldAlternateImagePosition(),
      shouldShowAddToCartSuccessAnimation: this.getAddToCartAction() === AddToCartActionOption.NONE,
      shouldShowClearFilters: this.shouldShowClearFilters(),
      shouldShowMobileFiltersModal: false,
      shouldShowSort: this.shouldShowSort(),
      shouldShowProductOptions: this.shouldShowProductOptions,
      showShowLightEmptyState: this.productsService.hideGallery,
      shouldUseAutoGridProductsCount: this.shouldUseAutoGridProductsCount,
      setSelectedSort: this.setSelectedSort.bind(this),
      sortProducts: this.sortProducts.bind(this),
      sortingOptions: this.getSortingOptions(),
      sortingOptionsWithoutDefault: this.geSortingOptionsWithoutDefault(),
      mobileFiltersPanelState: this.mobileFiltersPanelState(),
      textsMap: this.getTextsMap(),
      toggleFiltersModalVisibility: this.toggleFiltersModalVisibility.bind(this),
      totalProducts: this.productsService.totalCount,
      updateAddToCartStatus: this.updateAddToCartStatus.bind(this),
      experiments: this.getExperimentsToInject(),
      handleProductsOptionsChange: this.handleOptionSelectionsChange.bind(this),
      productsRequestInProgress: false,
      currentCategory: this.currentCategory,
      breadcrumbsHistory: this.breadcrumbsItems,
      allProductsCategoryId: this.productsService.allProductsCategoryId,
    };
  }

  private getExperimentsToInject() {
    return {
      shouldRenderGalleryProductItemCarouselHover: this.siteStore.experiments.enabled(
        Experiments.GalleryProductItemCarouselHover
      ),
      isAllowGalleryProductRoundCornersInViewer: this.siteStore.experiments.enabled(
        Experiments.AllowGalleryProductRoundCornersInViewer
      ),
      isGallerySeoTagsEnabled: this.isGallerySeoTagsEnabled(),
      shouldScrollToProductPositionWhenReturningFromProductPageEnabled:
        this.shouldScrollToProductPositionWhenReturningFromProductPageEnabled(),
      shouldApplyMobileFiltersEnhancements: this.siteStore.experiments.enabled(Experiments.MobileFiltersEnhancements),
      editableGridTemplateRepeatOption: this.siteStore.experiments.enabled(
        Experiments.EditableGridTemplateRepeatOption
      ),
      addRatingSummarySlotToGallery: this.siteStore.experiments.enabled(Experiments.AddRatingSummarySlotToGallery),
      fixGalleryRenderingWhenUrlChanges: this.siteStore.experiments.enabled(
        Experiments.FixGalleryRenderingWhenUrlChanges
      ),
      shouldUseCommonDiscountPricingMethods: this.siteStore.experiments.enabled(
        Experiments.ShouldUseCommonDiscountPricingMethods
      ),
      allowBreadcrumbsAlignmentCategoryPage: this.siteStore.experiments.enabled(
        Experiments.allowBreadcrumbsAlignmentCategoryPage
      ),
      useServerAllProductsCategoryId: this.siteStore.experiments.enabled(Experiments.UseServerAllProductsCategoryId),
    };
  }

  private getTextsMap(): ITextsMap {
    return {
      addToCartContactSeller: this.translations['gallery.contactSeller.button'],
      addToCartOutOfStock:
        this.multilingualService.get('gallery_oosButtonText') || this.translations['gallery.outOfStock.button'],
      addToCartSuccessSR: this.translations['gallery.sr.addToCartSuccess'],
      allCollectionsFilterButtonText: this.translations['filter.CATEGORY_ALL'],
      clearFiltersButtonText: this.translations['filter.CLEAN_ALL'],
      digitalProductBadgeAriaLabelText: this.translations['sr.digitalProduct'],
      galleryAddToCartButtonText:
        this.multilingualService.get('gallery_addToCartText') || this.translations['gallery.addToCart.button'],
      galleryAddToCartPreOrderButtonText:
        this.multilingualService.get('gallery_preOrderText') || this.translations['gallery.preOrder.button'],
      galleryRegionSR: this.translations['sr.region.GALLERY'],
      filtersSubmitButtonText: this.translations['gallery.mobile.filters.buttonApply'],
      filtersTitleText: this.multilingualService.get('FILTERS_MAIN_TITLE') || this.translations['filter.MAIN_TITLE'],
      filtersAriaLabel: this.translations['gallery.mobile.filters.buttonClose'],
      loadMoreButtonText: this.multilingualService.get('LOAD_MORE_BUTTON') || this.translations.LOAD_MORE_BUTTON,
      loadPreviousButtonText:
        this.multilingualService.get('gallery_loadPreviousText') || this.translations['gallery.loadPrevious.button'],
      mobileFiltersButtonText: this.translations['gallery.mobile.filters.title.button'],
      mobileFiltersAndSortingText:
        this.multilingualService.get('gallery_filtersAndSortMobileButtonText') || this.combinedFiltersAndSortingTitle(),
      sortByText: this.translations['gallery.sortBy.label.defaultText'],
      noProductsFilteredMessageText: this.translations.NO_PRODUCTS_FILTERED_MESSAGE_MAIN,
      noProductsMessageText: this.translations.NO_PRODUCTS_MESSAGE_MAIN,
      sortOptionNewestText: this.translations['sort.NEWEST'],
      sortOptionLowPriceText: this.translations['sort.PRICE_LOW'],
      sortOptionHighPriceText: this.translations['sort.PRICE_HIGH'],
      sortOptionNameAZText: this.translations['sort.NAME_AZ'],
      sortOptionNameZAText: this.translations['sort.NAME_ZA'],
      sortTitleText: this.multilingualService.get('SORTING_MAIN_TITLE') || this.translations.SORT_BY,
      sortRecommendedText: this.translations['gallery.sortingOptions.recommended.option'],
      productOutOfStockText:
        this.multilingualService.get('gallery_oosButtonText') || this.translations.OUT_OF_STOCK_LABEL,
      productPriceBeforeDiscountSR: this.translations['sr.PRODUCT_PRICE_BEFORE_DISCOUNT'],
      productPriceAfterDiscountSR: this.translations['sr.PRODUCT_PRICE_AFTER_DISCOUNT'],
      productPriceWhenThereIsNoDiscountSR: this.translations['sr.PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT'],
      quickViewButtonText: this.translations.QUICK_VIEW,
      quantityAddSR: this.translations['gallery.sr.addQty'],
      quantityChooseAmountSR: this.translations['gallery.sr.chooseQty'],
      quantityRemoveSR: this.translations['gallery.sr.removeQty'],
      quantityInputSR: this.translations['gallery.sr.quantity'],
      announceFiltersUpdate: this.translations['sr.ANNOUNCE_FOUND_ITEMS_ON_FILTERS_UPDATE'],
      quantityMaximumAmountSR: this.translations['gallery.exceedsQuantity.error'],
      quantityTotalSR: this.translations['gallery.sr.totalQty'],
      quantityMinimumAmountSR: this.translations['gallery.minimumQuantity.error'],
      arrowPrevious: this.translations['gallery.sr.carousel.previous.label'],
      carouselContainerLabel: this.translations['gallery.sr.carousel.container.label'],
      arrowNext: this.translations['gallery.sr.carousel.next.label'],
      priceRangeText: this.translations['gallery.price.from.label'],
      pricePerUnitSR: this.translations['gallery.sr.units.basePrice.label'],
      measurementUnits: this.getMeasurementUnitsTranslation(),
      priceRangeMaxSR: this.translations['gallery.sr.filters.priceRange.max'],
      priceRangeMinSR: this.translations['gallery.sr.filters.priceRange.min'],
      productsCounterPlural: this.translations['gallery.numberOfProducts.label.plural'],
      productsCounterSingular: this.translations['gallery.numberOfProducts.label.singular'],
      productsCounterZero: this.translations['gallery.numberOfProducts.label.none'],
      sortLabel:
        this.multilingualService.get('SORTING_MAIN_TITLE') || this.translations['gallery.sortBy.label.defaultText'],
      categoryTreeTitle:
        this.multilingualService.get('categoryTreeTitle') || this.translations['gallery.categories.title.defaultText'],
      categoryHeaderReadMoreLink: this.translations['gallery.description.readMore.link'],
      categoryHeaderReadLessLink: this.translations['gallery.description.readLess.link'],
      emptyCategoryTitle: this.translations['gallery.emptyState.emptyCategory.title'],
      emptyCategoryBody: this.translations['gallery.emptyState.emptyCategory.body'],
      noFilterResultsTitle: this.translations['gallery.emptyState.noFilterResults.title'],
      noFilterResultsBody: this.translations['gallery.emptyState.noFilterResults.body'],
      noFilterResultsButton: this.translations['gallery.emptyState.noFilterResults.button'],
      emptyCategoryEditorTitle: this.translations['gallery.editor.emptyState.title'],
      emptyCategoryEditorSubTitle: this.translations['gallery.editor.emptyState.subtitle'],
      emptyCategoryPageEditorTitle:
        this.translations['gallery.categoryPage.editor.noVisibleCategories.emptyState.title'],
      emptyCategoryPageEditorSubtitle:
        this.translations['gallery.categoryPage.editor.noVisibleCategories.emptyState.body'],
      allProducts: this.translations['gallery.collection.allProducts'],
      appliedFiltersClearAllButton: this.translations['gallery.appliedFilters.clearAll.button'],
      appliedFiltersPriceTag: this.translations['gallery.appliedFilters.priceRange.tag'],
      appliedFiltersContainerSR: this.translations['gallery.sr.appliedFilters.container'],
      appliedFilterClearSR: this.translations['gallery.sr.appliedFilters.clearFilter.button'],
    };
  }

  private getLinksForAllPages(): string[] {
    const numberOfPages = this.totalPages < 1000 ? this.totalPages : 1000;

    return _.times(numberOfPages, (i) => this.queryParamsService.getUrlWithCustomPageParamForSeo(i + 1));
  }

  private nextPrevLinksMapped(): {prevUrl: string; nextUrl: string} {
    const res = {prevUrl: '', nextUrl: ''};
    const prevIndex = this.currentPage - 1;
    const nextIndex = this.currentPage + 1;

    if (prevIndex > 0) {
      res.prevUrl = this.queryParamsService.getUrlWithCustomPageParamForSeo(prevIndex);
    }

    if (nextIndex <= this.totalPages) {
      res.nextUrl = this.queryParamsService.getUrlWithCustomPageParamForSeo(nextIndex);
    }

    return res;
  }

  private nextPrevLinks(): string[] {
    const {prevUrl, nextUrl} = this.nextPrevLinksMapped();
    return [prevUrl, nextUrl].filter((url) => url);
  }

  private getMeasurementUnitsTranslation() {
    return _.reduce(
      unitsTranslations,
      (result, types, unit) => {
        result[unit] = result[unit] || {};
        _.each(types, (translationKey, type) => {
          result[unit][type] = this.translations[translationKey];
        });
        return result;
      },
      {}
    );
  }

  private generateProductsManifest(products: IProduct[]): ProductsManifest {
    return products.reduce((acc: ProductsManifest, product) => {
      acc[product.id] = {
        url: this.getProductPageUrl(product.urlPart),
        addToCartState: this.addToCartService.getButtonState({
          price: actualPrice(product),
          inStock: product.isInStock,
          isPreOrderState: isPreOrder(product),
        }),
      };
      return acc;
    }, {});
  }

  private getProductPageUrl(slug) {
    return this.isUrlWithOverrides
      ? this.customUrlApi.buildProductPageUrl({slug})
      : `${this.productPageSectionUrl}/${slug}`;
  }

  private async filterProducts(filterId: number, selectionValue: IFilterSelectionValue) {
    this.fedopsLogger.interactionStarted(FedopsInteraction.Filter);

    const filterModel = this.filtersService.getFilterModel(filterId);
    this.filtersService.updateActiveFilterOption(filterModel, selectionValue);
    this.queryParamsService.updateFiltersQueryParams({
      filterModel,
      mainCollectionId: this.mainCollectionId,
      forceUpdateFromUrl: !this.siteStore.isMobile(),
    });

    if (this.siteStore.isMobile()) {
      if (this.siteStore.experiments.enabled(Experiments.MobileFiltersEnhancements)) {
        await this.updateComponentWithFilteredProducts();
      } else {
        this.updateComponent({
          filterModels: this.filtersService.getFilterModels(),
          hasSelectedFilters: this.hasSelectedFilters(),
        });
      }
      this.fedopsLogger.interactionEnded(FedopsInteraction.Filter);
      return;
    }

    this.siteStore.webBiLogger.report(
      clickToChangeGalleryFiltersSf({
        filterType: this.filtersService.getFilterModel(filterId).filterType,
      })
    );
    await this.updateComponentWithFilteredProducts();
    this.fedopsLogger.interactionEnded(FedopsInteraction.Filter);
  }

  private setCurrentCategory(category: ICategory) {
    this.currentCategory = category;
    this.mainCollectionId = this.currentCategory.id;
    this.productsService.setMainCollection(this.currentCategory.id);
    this.filtersService.setMainCollectionId(this.currentCategory.id);
    this.setBreadcrumbsHistory();
  }

  private async applyFilteredProductsOnMobile() {
    this.fedopsLogger.interactionStarted(FedopsInteraction.MobileFilter);
    this.siteStore.webBiLogger.report(
      galleryClickApplyFilter({
        filterTypes: this.filtersService.getSelectedFilterTypes().toString(),
      })
    );

    if (!this.siteStore.experiments.enabled(Experiments.MobileFiltersEnhancements)) {
      this.filtersService.updateSnapshotWithActiveOptions();
    }

    this.queryParamsService.applyQueryParamsStateOnUrl();

    this.updateComponent(
      this.siteStore.experiments.enabled(Experiments.MobileFiltersEnhancements)
        ? {numberOfSelectedFilterTypes: this.filtersService.getSelectedFilterOptionsCount()}
        : {
            loading: true,
            numberOfSelectedFilterTypes: this.filtersService.getSelectedFilterTypes().length,
          }
    );

    await this.updateComponentWithFilteredProducts();
    this.updateComponent(
      this.siteStore.experiments.enabled(Experiments.MobileFiltersEnhancements)
        ? {shouldShowMobileFiltersModal: false}
        : {loading: false, shouldShowMobileFiltersModal: false}
    );
    this.fedopsLogger.interactionEnded(FedopsInteraction.MobileFilter);
  }

  private async clearFilters(forceApply: boolean = true) {
    this.filtersService.resetFilters();

    this.queryParamsService.clearAllFiltersQueryParams(
      this.filtersService.getFilterModels().map((filterModel) => filterModel.title)
    );

    this.siteStore.webBiLogger.report(galleryClickClearAllFilters({}));

    if (this.siteStore.isMobile() && forceApply) {
      return this.applyFilteredProductsOnMobile();
    } else {
      await this.updateComponentWithFilteredProducts();
    }
  }

  private toggleFiltersModalVisibility(show: boolean) {
    if (this.siteStore.experiments.enabled(Experiments.MobileFiltersEnhancements)) {
      if (show) {
        this.siteStore.webBiLogger.report(galleryClickFilter({}));
      }
    } else if (show) {
      this.filtersService.updateSnapshotWithActiveOptions();
      this.siteStore.webBiLogger.report(galleryClickFilter({}));
    } else {
      this.filtersService.updateActiveOptionsWithSnapshot();
    }

    this.updateComponent({
      shouldShowMobileFiltersModal: show,
      filterModels: this.filtersService.getFilterModels(),
      hasSelectedFilters: this.hasSelectedFilters(),
    });
  }

  private async getFilterProducts({limit}: {limit?: number}) {
    const filterDTO = this.filtersService.getFilterDTO();
    const shouldSpecificCollectionQuery = this.filtersService.shouldSpecificCollectionQuery(this.mainCollectionId);
    return this.productsService.filterProducts({
      filters: filterDTO,
      collectionIds: this.filtersService.getCollectionIdsFilterDTO(),
      shouldSpecificCollectionQuery,
      limit,
    });
  }

  private async updateComponentWithFilteredProducts() {
    this.products = await this.getFilterProducts({});

    this.currentPage = 1;

    this.queryParamsService.updatePageQueryParam();

    if (this.siteStore.experiments.enabled(Experiments.AddRatingSummarySlotToGallery)) {
      this.setSlotProps(this.products);
    }
    this.updateComponent({
      ...this.getComputedProps(this.products),
      filterModels: this.filtersService.getFilterModels(),
      hasMoreProductsToLoad: this.productsService.hasMoreProductsToLoad(),
      hasSelectedFilters: this.hasSelectedFilters(),
      shouldShowClearFilters: this.shouldShowClearFilters(),
      shouldShowSort: this.shouldShowSort(),
      currentCategory: this.currentCategory,
      breadcrumbsHistory: this.breadcrumbsItems,
      numberOfSelectedFilterTypes: this.getNumberOfSelectedFilterTypes(),
    });
  }

  private hasSelectedFilters(): boolean {
    return this.filtersService.hasSelectedFilters();
  }

  private readonly loadMoreType = (): LoadMoreType => {
    return this.styles.gallery_loadMoreProductsType;
  };

  public updateAppOnQueryParamsChange({isPageUpdated}: {isPageUpdated: boolean}) {
    /* istanbul ignore else: will be when other query params will be treated by side effect */
    if (isPageUpdated) {
      this.currentPage = this.queryParamsService.getPageQueryParam();
      if (this.loadMoreType() === LoadMoreType.PAGINATION) {
        void this.handlePaginationBySideEffect();
      } else {
        void this.loadMoreProductsBySideEffect();
      }
    }
  }

  /* istanbul ignore next: will be fully tested when experiment "specs.stores.FixGalleryRenderingWhenPageChanges" will be merged */
  private getNumberOfVisibleProducts(): number {
    if (this.currentPage <= 1) {
      return this.maxProductsPerPage;
    }

    return this.products.length;
  }

  private async loadMoreProductsBySideEffect() {
    const visibleProducts = this.getNumberOfVisibleProducts();

    const fedopsInteraction =
      this.loadMoreType() === LoadMoreType.BUTTON ? FedopsInteraction.LoadMore : FedopsInteraction.InfiniteScroll;
    this.fedopsLogger.interactionStarted(fedopsInteraction);
    this.productsService.setProductsPerPage(this.maxProductsPerPage);

    const shouldSpecificCollectionQuery = this.filtersService.shouldSpecificCollectionQuery(this.mainCollectionId);
    this.products = await this.productsService.loadMoreProducts({
      visibleProducts,
      shouldSpecificCollectionQuery,
    });
    if (this.products === null) {
      this.updateComponent({
        hasMoreProductsToLoad: false,
        productsRequestInProgress: false,
      });
      return;
    }
    this.updateComponent({
      ...this.getComputedProps(this.products),
      focusedProductIndex: visibleProducts,
      linkForAllPages: this.getLinksForAllPages(),
      nextPrevLinks: this.nextPrevLinks(),
      hasMoreProductsToLoad: this.productsService.hasMoreProductsToLoad(),
      productsRequestInProgress: false,
    });

    if (this.siteStore.experiments.enabled(Experiments.AddRatingSummarySlotToGallery)) {
      this.setSlotProps(this.products);
    }
    this.fedopsLogger.interactionEnded(fedopsInteraction);
  }

  /* istanbul ignore next: will be fully tested when experiment "specs.stores.FixGalleryRenderingWhenPageChanges" will be merged */
  private async loadMoreProducts(visibleProducts: number, maxProductsPerPage: number = this.maxProductsPerPage) {
    if (this.siteStore.experiments.enabled(Experiments.FixGalleryRenderingWhenPageChanges)) {
      this.updateComponent({
        productsRequestInProgress: true,
      });
      this.siteStore.webBiLogger.report(
        clickLoadMoreInGallerySf({
          ...this.getBICollection(),
          type: this.loadMoreType() === LoadMoreType.BUTTON ? BiEventParam.LoadMore : BiEventParam.InfiniteScroll,
        })
      );
      this.queryParamsService.updatePageQueryParam(this.currentPage + 1);
      return;
    }

    this.updateComponent({
      productsRequestInProgress: true,
    });

    const fedopsInteraction =
      this.loadMoreType() === LoadMoreType.BUTTON ? FedopsInteraction.LoadMore : FedopsInteraction.InfiniteScroll;
    this.fedopsLogger.interactionStarted(fedopsInteraction);
    this.siteStore.webBiLogger.report(
      clickLoadMoreInGallerySf({
        ...this.getBICollection(),
        type: this.loadMoreType() === LoadMoreType.BUTTON ? BiEventParam.LoadMore : BiEventParam.InfiniteScroll,
      })
    );
    this.productsService.setProductsPerPage(maxProductsPerPage);
    this.currentPage++;

    this.queryParamsService.updatePageQueryParam(this.currentPage);

    const shouldSpecificCollectionQuery = this.filtersService.shouldSpecificCollectionQuery(this.mainCollectionId);
    this.products = await this.productsService.loadMoreProducts({
      visibleProducts,
      shouldSpecificCollectionQuery,
    });
    if (this.products === null) {
      this.updateComponent({
        hasMoreProductsToLoad: false,
        productsRequestInProgress: false,
      });
      return;
    }
    this.updateComponent({
      ...this.getComputedProps(this.products),
      focusedProductIndex: visibleProducts,
      linkForAllPages: this.getLinksForAllPages(),
      nextPrevLinks: this.nextPrevLinks(),
      hasMoreProductsToLoad: this.productsService.hasMoreProductsToLoad(),
      productsRequestInProgress: false,
    });

    if (this.siteStore.experiments.enabled(Experiments.AddRatingSummarySlotToGallery)) {
      this.setSlotProps(this.products);
    }
    this.fedopsLogger.interactionEnded(fedopsInteraction);
  }

  private readonly hasOptions = (product: IProduct) => !!product.options.length;

  private readonly getAddToCartAction = () => this.styles.gallery_addToCartAction;

  private readonly updateAddToCartStatus = (productId: string, status: AddToCartActionStatus) => {
    this.addedToCartStatus[productId] = status;
    this.updateComponent({addedToCartStatus: this.addedToCartStatus});
  };

  private handleAddToCart({productId, index, quantity}: {productId: string; index: number; quantity: number}) {
    this.updateAddToCartStatus(productId, AddToCartActionStatus.IN_PROGRESS);

    this.productsService
      .addToCart({
        productId,
        index,
        compId: this.compId,
        externalId: this.config.externalId,
        quantity,
        addToCartAction: this.getAddToCartAction(),
      })
      .then(() => this.updateAddToCartStatus(productId, AddToCartActionStatus.SUCCESSFUL));
  }

  private readonly getSortFromQueryParam = (): ISortingOption => {
    return this.sortService.getSort(this.queryParamsService.getQueryParam('sort'));
  };

  private readonly getProductsLimitByPageFromQueryParam = (productPerPage: number): number => {
    return parseInt(this.queryParamsService.getQueryParam(DefaultQueryParamKeys.Page), 10) * productPerPage;
  };

  private readonly getFiltersFromQueryParams = (filterModels: FilterModel[]) => {
    const queryParamsFilters = this.queryParamsService.getFiltersQueryParams(filterModels);
    if (queryParamsFilters) {
      this.filtersService.updateActiveFilterOptionsByQueryParams(queryParamsFilters);
    }
    return this.filtersService.getFilterModels();
  };

  private setSelectedSort(sorting: ISorting) {
    const selectedSort = this.sortService.setSelectedSort(sorting.id);
    this.queryParamsService.updateSortQueryParams(this.sortService.getSelectedSort(), false);

    this.updateComponent({
      selectedSort,
    });
  }

  private async sortProducts(sorting: ISorting) {
    this.fedopsLogger.interactionStarted(FedopsInteraction.Sort);
    this.queryParamsService.updateSortQueryParams(sorting, true);

    const selectedSort = this.sortService.setSelectedSort(sorting.id);
    this.siteStore.webBiLogger.report(sortGallerySf({sortDir: sorting.direction, method: sorting.id.split('_')[0]}));
    const shouldSpecificCollectionQuery = this.filtersService.shouldSpecificCollectionQuery(this.mainCollectionId);
    this.products = await this.productsService.sortProducts(
      sorting.field ? sorting : null,
      shouldSpecificCollectionQuery
    );

    this.currentPage = 1;

    this.updateComponent({
      ...this.getComputedProps(this.products),
      hasMoreProductsToLoad: this.productsService.hasMoreProductsToLoad(),
      isFirstPage: false,
      selectedSort,
    });

    if (this.siteStore.experiments.enabled(Experiments.AddRatingSummarySlotToGallery)) {
      this.setSlotProps(this.products);
    }
    this.fedopsLogger.interactionEnded(FedopsInteraction.Sort);
  }

  private isHorizontalLayout() {
    const {gallery_imagePlacement, 'mobile:gallery_imagePlacement': mobileImagePlacement} = this.styles;
    const imagePlacement = this.getStyleParamByDevice(mobileImagePlacement, gallery_imagePlacement).value;

    return imagePlacement === ImagePlacements.HORIZONTAL;
  }

  private shouldAlternateImagePosition() {
    const {
      gallery_alternateImagePosition: desktopAlternateImagePosition,
      'mobile:gallery_alternateImagePosition': mobileAlternateImagePosition,
    } = this.styles;

    return this.getStyleParamByDevice(mobileAlternateImagePosition, desktopAlternateImagePosition);
  }

  private imagePosition(): ImagePositions {
    const {gallery_imagePosition: desktopImagePosition, 'mobile:gallery_imagePosition': mobileImagePosition} =
      this.styles;

    return this.getStyleParamByDevice<ImagePositions>(
      mobileImagePosition?.value as ImagePositions,
      desktopImagePosition?.value as ImagePositions
    );
  }

  protected get imageMode(): ImageModeId {
    const {gallery_imageMode: desktopImageMode, 'mobile:gallery_imageMode': mobileImageMode} = this.styles;

    return this.getStyleParamByDevice(mobileImageMode, desktopImageMode);
  }

  protected get imageRatio(): ImageRatioId {
    const {galleryImageRatio: desktopImageRatio, 'mobile:galleryImageRatio': mobileImageRatio} = this.styles;
    return this.getStyleParamByDevice(mobileImageRatio, desktopImageRatio);
  }

  private shouldShowSort() {
    const {
      galleryShowSort,
      gallerySortNewest,
      gallerySortPriceAsc,
      gallerySortPriceDes,
      gallerySortNameAsc,
      gallerySortNameDes,
      gallerySortRecommended,
    } = this.styles;
    const isAnySortActive =
      gallerySortNewest ||
      gallerySortPriceAsc ||
      gallerySortPriceDes ||
      gallerySortNameAsc ||
      gallerySortNameDes ||
      (this.isCategoryPage && gallerySortRecommended);

    return (
      galleryShowSort &&
      isAnySortActive &&
      ((this.isCategoryPage &&
        (this.siteStore.experiments.enabled(Experiments.MobileFiltersEnhancements) || this.siteStore.isDesktop())) ||
        this.productsService.products?.length > 0)
    );
  }

  private openQuickView({
    productId,
    index,
    selectionIds,
    quantity,
  }: {
    productId: string;
    index: number;
    selectionIds?: number[];
    quantity?: number;
  }) {
    this.productsService.quickViewProduct(productId, index, {
      compId: this.compId,
      externalId: this.config.externalId,
      selectionIds,
      quantity,
    });
  }

  private readonly shouldScrollToProductPositionWhenReturningFromProductPageEnabled = () =>
    this.siteStore.experiments.enabled(Experiments.GalleryScrollToProductPositionWhenReturningFromProductPage);

  private handleProductItemClick({
    biData: {productId, index},
  }: {
    biData: {
      productId: string;
      index: number;
    };
  }) {
    const product = this.productsService.getProduct(productId);

    if (this.shouldScrollToProductPositionWhenReturningFromProductPageEnabled()) {
      this.queryParamsService.updateScrollToProduct(product.urlPart);
    }

    this.productsService.storeNavigation(this.siteStore.siteApis.currentPage.id);

    this.siteStore.webBiLogger.report(
      clickOnProductBoxSf({
        productId,
        hasRibbon: !!product.ribbon,
        hasOptions: this.hasOptions(product),
        index,
        productType: product.productType,
        galleryType: this.type,
        galleryProductsLogic:
          this.mainCollectionId !== this.productsService.allProductsCategoryId ? 'collection' : 'All products',
      })
    );
    this.productsService.sendClickTrackEvent(product, index);

    if (this.veloInputs?.onItemSelected) {
      this.veloInputs.onItemSelected.callBack(product.id);
      /* istanbul ignore next: WIP, having issues with spy on navigation. will be tested soon */
      if (this.veloInputs.onItemSelected.options.preventNavigation) {
        /* istanbul ignore next: WIP, having issues with spy on navigation. will be tested soon */
        return;
      }
    }

    this.siteStore.navigate(
      {
        sectionId: PageMap.PRODUCT,
        state: product.urlPart,
        queryParams: undefined,
      },
      true
    );
  }

  private get categories() {
    return this.categoriesService?.visibleCategories ?? [];
  }

  private fetchFilters(): Promise<FilterModel[]> {
    return this.filtersService.fetchFilters();
  }

  private shouldShowFilters(): boolean {
    const galleryShowFilters = this.styles.galleryShowFilters;
    return galleryShowFilters && this.filterConfigsService.shouldShowFilters();
  }

  private getSortingOptions(): ISortingOption[] {
    return sortOptions.filter(
      (o) => this.styles[o.settingsShouldDisplayKey] !== false && o.id !== SortOptionsIds.Recommended
    );
  }

  private geSortingOptionsWithoutDefault(): ISortingOption[] {
    return sortOptions.filter(
      (o) => this.styles[o.settingsShouldDisplayKey] !== false && o.id !== SortOptionsIds.Default
    );
  }

  private getNumberOfSelectedFilterTypes() {
    return this.siteStore.experiments.enabled(Experiments.MobileFiltersEnhancements)
      ? this.filtersService.getSelectedFilterOptionsCount()
      : this.filtersService.getSelectedFilterTypes().length;
  }

  private mobileFiltersPanelState(): MobileFiltersPanelState {
    const hasFilters = this.filtersService?.getFilterModels()?.length > 0;
    const shouldShowFilters = this.styles.galleryShowFilters && hasFilters;
    const shouldShowSort = this.shouldShowSort();

    if (shouldShowFilters && !shouldShowSort) {
      return MobileFiltersPanelState.FILTERS_ONLY;
    }

    if (!shouldShowFilters && shouldShowSort) {
      return MobileFiltersPanelState.SORT_ONLY;
    }

    if (shouldShowFilters && shouldShowSort) {
      return MobileFiltersPanelState.FILTERS_AND_SORT;
    }

    return MobileFiltersPanelState.NONE;
  }

  private combinedFiltersAndSortingTitle() {
    switch (this.mobileFiltersPanelState()) {
      case MobileFiltersPanelState.FILTERS_ONLY:
        return this.translations['gallery.mobile.filter.defaultText'];
      case MobileFiltersPanelState.SORT_ONLY:
        return this.translations['gallery.mobile.sort.defaultText'];
      case MobileFiltersPanelState.FILTERS_AND_SORT:
      default:
        return this.translations['gallery.mobile.filterAndSort.defaultText'];
    }
  }

  private sendSortClickBiEvent() {
    this.siteStore.webBiLogger.report(galleryClickSortBy({}));
  }

  private updatePublicData(newPublicData: IGalleryControllerConfig['publicData']) {
    this.config.publicData = newPublicData;
  }

  public async updateState(
    config: IGalleryControllerConfig,
    newPublicData: IGalleryControllerConfig['publicData'] & {appSettings?: any}
  ): Promise<void> {
    this.config = config;
    const previousShouldShowProductOptions = this.shouldShowProductOptions;
    const oldStyles = this.styles;
    this.updateStyles();

    this.updatePublicData(newPublicData);

    if (newPublicData.appSettings) {
      this.multilingualService.setWidgetSettings(newPublicData.appSettings);
      if (newPublicData.appSettings.FILTERS) {
        this.filterConfigsService.setFilterConfigDTOs(newPublicData.appSettings.FILTERS);
        this.filtersService.deleteFilterModels();
      }
    }

    if (!this.shouldShowFilters()) {
      this.filtersService.deleteFilterModels();
      this.updateComponent({filterModels: []});
    } else if (this.filtersService.getFilterModels().length === 0) {
      const filterModels = await this.filtersService.fetchFilters();
      this.updateComponent({filterModels});
    }

    const shouldUpdateCategoryList =
      this.styles.gallery_categoryListStrategy !== oldStyles.gallery_categoryListStrategy ||
      !!newPublicData?.appSettings?.CATEGORY_LIST_CONFIG;

    if (shouldUpdateCategoryList) {
      this.categoriesService.updateVisibleCategories({
        categoryListConfig: newPublicData?.appSettings?.CATEGORY_LIST_CONFIG,
        shouldUseCategoryListConfig: this.styles.gallery_categoryListStrategy === CategoryListStrategy.MANUALLY,
      });
    }

    let nextProps: Partial<IPropsInjectedByViewerScript> = {
      isAutoGrid: this.isAutoGrid(),
      loadMoreType: this.loadMoreType(),
      shouldShowAddToCartSuccessAnimation: this.getAddToCartAction() === AddToCartActionOption.NONE,
      shouldShowSort: this.shouldShowSort(),
      sortingOptions: this.getSortingOptions(),
      sortingOptionsWithoutDefault: this.geSortingOptionsWithoutDefault(),
      mobileFiltersPanelState: this.mobileFiltersPanelState(),
      textsMap: this.getTextsMap(),
      shouldShowProductOptions: this.shouldShowProductOptions,
      isOptionsRevealEnabled: this.getIsOptionsRevealEnabled(),
      isHorizontalLayout: this.isHorizontalLayout(),
      shouldAlternateImagePosition: this.shouldAlternateImagePosition(),
      imagePosition: this.imagePosition(),
      shouldShowImageCarousel: this.shouldShowImageCarousel,
      maxProductsPerPage: this.maxProductsPerPage,
      totalPages: this.totalPages,
      shouldUseAutoGridProductsCount: this.shouldUseAutoGridProductsCount,
      categories: this.categories,
    };

    const shouldUpdateWithOptions = previousShouldShowProductOptions !== this.shouldShowProductOptions;
    const isFixedGridProductsCountChanged =
      this.styles.gallery_fixedGridProductsCount !== oldStyles.gallery_fixedGridProductsCount;
    const isDefaultSortingChanged =
      this.styles.gallery_sortingDefaultOption.value !== oldStyles.gallery_sortingDefaultOption.value;
    const shouldFetchProducts =
      shouldUpdateWithOptions ||
      this.styles.gallery_productsCount !== oldStyles.gallery_productsCount ||
      isDefaultSortingChanged ||
      isFixedGridProductsCountChanged;

    this.productsService.setWithOptions(this.shouldShowProductOptions);

    if (shouldFetchProducts) {
      if (isFixedGridProductsCountChanged) {
        this.setGalleryRows(true);
        this.productsService.setProductsPerPage(this.maxProductsPerPage);
      }

      if (isDefaultSortingChanged) {
        nextProps.selectedSort = this.sortService.setSelectedSortBySettingsShouldDisplayKey(
          this.styles.gallery_sortingDefaultOption.value
        );

        this.productsService.updateSort(nextProps.selectedSort);
      }

      const shouldSpecificCollectionQuery = this.filtersService.shouldSpecificCollectionQuery(this.mainCollectionId);
      nextProps.products = this.products = await this.productsService.loadProducts({
        from: 0,
        to: this.productsService.getProductPerPage(),
        shouldSpecificCollectionQuery,
      });
      nextProps = {
        ...nextProps,
        maxProductsPerPage: this.maxProductsPerPage,
        productsManifest: this.generateProductsManifest(nextProps.products),
        totalPages: this.totalPages,
      };
    }

    nextProps.productsPriceRangeMap = this.productsService.productPriceRangeMap;
    this.updateComponent({...nextProps, ...this.getCommonPropsToUpdate()});
  }

  private getBICollection() {
    const collectionId = this.productsService.getMainCollectionId();
    return collectionId === this.productsService.allProductsCategoryId ? {} : {categoryId: collectionId};
  }

  private async loadProductsByPage(page: number) {
    const from = this.productsPerPage * (page - 1);
    const to = from + this.productsPerPage;
    const shouldSpecificCollectionQuery = this.filtersService.shouldSpecificCollectionQuery(this.mainCollectionId);
    return this.productsService.loadProducts({
      from,
      to,
      shouldSpecificCollectionQuery,
    });
  }

  private handleCategoryClick({
    destinationLink,
    destinationCategoryId,
  }: {
    destinationLink: string;
    destinationCategoryId: string;
  }) {
    return this.siteStore.webBiLogger.report(
      categoryPageCategoryTreeClicked({
        link: destinationLink,
        destinationCategoryId,
        originCategoryId: this.currentCategory.id,
      })
    );
  }

  private handleCategoryClampClick(isClamped: boolean) {
    return isClamped
      ? this.siteStore.webBiLogger.report(categoryPageHeroSectionReadLessClicked({}))
      : this.siteStore.webBiLogger.report(categoryPageHeroSectionReadMoreClicked({}));
  }

  private handleCategoryBreadcrumbsClick({item, originCategoryId}: {item: BreadcrumbsItem; originCategoryId: string}) {
    return this.siteStore.webBiLogger.report(
      categoryPageBreadcrumbClicked({
        link: item.href,
        originCategoryId,
        destinationCategoryId: item.id.toString(),
      })
    );
  }

  private async handlePaginationBySideEffect() {
    this.fedopsLogger.interactionStarted(FedopsInteraction.Pagination);
    this.products = await this.loadProductsByPage(this.currentPage);
    this.updateComponent(this.getComputedProps(this.products));
    if (this.siteStore.experiments.enabled(Experiments.AddRatingSummarySlotToGallery)) {
      this.setSlotProps(this.products);
    }
    this.fedopsLogger.interactionEnded(FedopsInteraction.Pagination);
  }

  /* istanbul ignore next: will be fully tested when experiment "specs.stores.FixGalleryRenderingWhenPageChanges" will be merged */
  private async handlePagination(page: number) {
    if (this.siteStore.experiments.enabled(Experiments.FixGalleryRenderingWhenPageChanges)) {
      this.queryParamsService.updatePageQueryParam(page);
      this.siteStore.webBiLogger.report(
        clickLoadMoreInGallerySf({...this.getBICollection(), type: BiEventParam.Pagination})
      );

      return;
    }

    this.fedopsLogger.interactionStarted(FedopsInteraction.Pagination);
    this.products = await this.loadProductsByPage(page);
    this.currentPage = page;
    this.siteStore.webBiLogger.report(
      clickLoadMoreInGallerySf({...this.getBICollection(), type: BiEventParam.Pagination})
    );
    this.queryParamsService.updatePageQueryParam(page);
    this.updateComponent(this.getComputedProps(this.products));
    if (this.siteStore.experiments.enabled(Experiments.AddRatingSummarySlotToGallery)) {
      this.setSlotProps(this.products);
    }
    this.fedopsLogger.interactionEnded(FedopsInteraction.Pagination);
  }

  private getComputedProps(products: IProduct[]) {
    this.productsManifest = this.generateProductsManifest(products);
    return {
      currentPage: this.currentPage,
      scrollToProduct: this.scrollToProduct,
      isFirstPage: false,
      productsManifest: this.productsManifest,
      products,
      totalProducts: this.productsService.totalCount,
      totalPages: this.totalPages,
      productsVariantInfoMap: this.productsVariantInfoMap,
      productsPriceRangeMap: this.productsService.productPriceRangeMap,
      shouldShowImageCarousel: this.shouldShowImageCarousel,
      isGalleryRowsAndColsWereModified: this.isGalleryRowsAndColsWereModified,
    };
  }

  private readonly isEditorX = () => {
    return this.styles.responsive === true;
  };

  private readonly isAutoGrid = () => {
    if (this.siteStore.isMobile() && !this.isEditorX()) {
      return false;
    }

    return this.styles.gallery_gridType === GridType.AUTO;
  };

  private get shouldUseAutoGridProductsCount() {
    return this.styles.gallery_gridType === GridType.AUTO;
  }

  private getPaginationMode(): PaginationTypeName {
    const paginationFormat = this.styles.gallery_paginationFormat;

    return this.siteStore.isMobile() || paginationFormat === PaginationType.COMPACT ? 'compact' : 'pages';
  }

  private readonly setBreadcrumbsHistory = () => {
    this.breadcrumbsItems = [
      {id: 'home', href: this.homepageUrl, value: this.translations['gallery.breadcrumbs.firstItem']},
      {
        id: this.currentCategory.id,
        href: '',
        value:
          this.currentCategory.name === 'All Products' ? this.getTextsMap().allProducts : this.currentCategory.name,
      },
    ];
  };

  protected get stylesParams() {
    return this.isCategoryPage ? categoryStylesParams : gridGalleryStylesParams;
  }
}
